import React, { ReactElement } from "react"
import { Link, graphql } from "gatsby"
import type { PageProps } from "gatsby"

import parse from "html-react-parser"

import Layout from "../components/UI/Layout"
import Seo from "../components/UI/Seo"
import Bio from "../components/Content/Bio"
import FeaturedImage from "../components/Content/FeaturedImage"

interface TagIndexProps {
  tagId: string,
}
type TaxIndexPageProps = PageProps<GatsbyTypes.WordPressTagArchiveQuery, TagIndexProps>

const TagIndex = ({
  data,
  pageContext,
}: TaxIndexPageProps) => {

  const posts = data?.wpTag?.posts
  const projects = data?.wpTag?.projects

  const allPosts = [
    ...posts?.nodes!,
    ...projects?.nodes!,
  ].sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b?.date!).getTime() - new Date(a?.date!).getTime();
  })

  if (!allPosts.length) {
    return (
      <Layout pageTitle={`Tag: ${data?.wpTag?.name}`}>
        <Seo title={`Tag: ${data?.wpTag?.name}`} description={data?.wpTag?.description} />
        <Bio />
        <p>
          {`No posts found for the tag ${data?.wpTag?.name} :(`}
        </p>
      </Layout>
    )
  }



  return (
    <Layout pageTitle={`Tag: ${data?.wpTag?.name}`}>
      <Seo title={`Tag: ${data?.wpTag?.name}`} description={data?.wpTag?.description} />

      <div className="text-left mb-36">
        <ol>
          {allPosts.map(node => {

            if ( node?.status !== "publish" ) return

            return (
              <li key={node?.id!}>
                <Link to={node?.uri || ""}>
                  <article
                    className="post-list-item"
                    itemScope
                    itemType="http://schema.org/Article"
                  >
                    <header>
                      <h2 className="font-bold text-lg mb-4">
                        <span className="tracking-wide text-gray-700 not-italic"
                          itemProp="headline">{parse(node?.title!)}</span>
                      </h2>
                    </header>
                    <FeaturedImage image={node.featuredImage?.image} />
                    <section className="text-black">{parse(node?.excerpt!)}</section>
                    <hr className="block mt-5 mb-10 w-16 border-black" />
                  </article>
                </Link>
              </li>
            )
          })}
        </ol>

      </div>

    </Layout>
  )
}

export default TagIndex

export const pageQuery = graphql`
  query WordPressTagArchive($tagId: String!) {
    wpTag(
      id: {eq: $tagId}
    ) {
      name
      description
      posts {
        nodes {
          id
          uri
          status
          title
          nodeType
          excerpt
          date
          ...PostFeaturedImage
        }
      }
      projects {
        nodes {
          id
          uri
          status
          title
          nodeType
          excerpt
          date
          ...ProjectFeaturedImage
        }
      }
    }
  }
`
